import React from 'react';
import { useHistory } from 'react-router-dom';

// Material
import { Box, Grid, Card, CardContent, Container, IconButton, Typography } from '@material-ui/core';

//Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

//Components
import TableDocuments from './TableDocuments';

//Utils
import { composedComponent } from 'utils/functions';
import Paths from 'utils/paths';

const ContractDocumentsComponent = (props) => {
  const { classes } = props;
  const history = useHistory()

  return (
    <Container className={classes.container} maxWidth={false}>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item md={12} lg={12} xs={12}>
          <IconButton edge="start" onClick={() => history.push(Paths.HOME)}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box marginBottom='30px'>
                <Typography variant="h3" component="div">
                  Documentos asociados al contrato
                </Typography>
              </Box>
              <TableDocuments />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ContractDocumentsComponent
