import { LOGIN } from "screens/Login/reducer"
import { createReducer, createAction } from "utils/reducer"

import { MAINTAINERUSER } from "screens/Maintainers/User/reducer"
import { MAINTAINERWORKSHOP } from "screens/Maintainers/WorkShop/reducer"
import { MAINTAINERVEHICLE } from "screens/Maintainers/Vehicle/reducer"
import { MAINTAINEREVENTTYPE } from "screens/Maintainers/EventType/reducer"
import { MAINTAINERPREVENTIVEMAINTENANCE } from "screens/Maintainers/PreventiveMaintenance/reducer"

import { normalizeObj, removeManyKeysByFilter } from "utils/functions"

export const APP = {
  GET_USER: "app/GET_USER",
  GET_USER_SUCCESS: "app/GET_USER_SUCCESS",
  GET_USER_FAIL: "app/GET_USER_FAIL",
  GET_STATE_FROM_API: "app/GET_API_FROM_STATE",
  GET_STATE_FROM_API_SUCCESS: "app/GET_API_FROM_STATE_SUCCESS",
  LOG_OUT: "app/LOG_OUT",
  TOGGLE_DRAWER: "app/TOGGLE_DRAWER",
  TOGGLE_ERROR: "app/TOGGLE_ERROR",
  TOGGLE_SUCCESS: "app/TOGGLE_SUCCESS",
  TOGGLE_MODAL_DOWNLOAD: "app/TOGGLE_MODAL_DOWNLOAD",
  SET_ERROR: "app/SET_ERROR",
  SET_SUCCESS: "app/SET_SUCCESS",
  SET_LOADER: "app/SET_LOADER",
}
export const appActions = {
  logOut: createAction(APP.LOG_OUT),
  getUser: createAction(APP.GET_USER),
  setUser: createAction(APP.GET_USER_SUCCESS, "payload"),
  toggleError: createAction(APP.TOGGLE_ERROR),
  toggleSuccess: createAction(APP.TOGGLE_SUCCESS),
  toggleDrawer: createAction(APP.TOGGLE_DRAWER),
  toggleModalDownload: createAction(APP.TOGGLE_MODAL_DOWNLOAD, "payload"),
  setError: createAction(APP.SET_ERROR, "payload"),
  setSuccess: createAction(APP.SET_SUCCESS, "msg"),
  setLoader: createAction(APP.SET_LOADER, "id", "state"),
}

const initialState = {
  drawerIsOpen: false,
  loadingUser: true,
  errorMsg: "",
  error: false,
  successMsg: "",
  success: false,
  objects: {
    users: {},
    roles: {},
    clients: {},
    vehicles: {},
    vehicleTypes: {},
    workshops: {},
    eventTypes: {},
    preventiveMaintenances: {},
    preventiveMaintenanceFiles: {},
    files: {},
  },
  user: {},
  loaders: {},
  device: {},
  deviceError: "",
  url_file: "",
  modalDownload: false,
}

const mergeObjects = (state, action) => {
  const newObjects = {}
  Object.keys({ ...state.objects, ...action.payload.objects }).forEach((key) => {
    newObjects[key] = {
      ...state.objects[key],
      ...(action.payload.objects[key] || {}),
    }
  })
  state.objects = newObjects
}

const deleteObject = (state, action) => {
  state.objects[action.payload.type] = normalizeObj(
    Object.values(state.objects[action.payload.type]).filter(
      (item) => item.id !== action.payload.item.ids[action.payload.type][0]
    )
  )
}

const deleteObjects = (state, action) => {
  Object.keys(action.payload.response.ids).forEach((key) => {
    state.objects[key] = removeManyKeysByFilter(
      state.objects[key],
      action.payload.response.ids[key]
    )
  })
}

const mergeObjectWithoutManyRelations = (state, action) => {
  const newObjects = {}
  Object.keys({ ...state.objects, ...action.payload.objects }).forEach((key) => {
    if (!key.localeCompare("preventiveMaintenanceFiles")) {
      newObjects[key] = {
        ...(action.payload.objects[key] || {}),
      }
    } else {
      newObjects[key] = {
        ...state.objects[key],
        ...(action.payload.objects[key] || {}),
      }
    }
  })
  state.objects = newObjects
}

const app = createReducer(initialState, {
  [MAINTAINERUSER.GET_STATE_FROM_API_USERS_SUCCESS]: mergeObjects,
  [MAINTAINERUSER.GET_STATE_FROM_API_ROLES_SUCCESS]: mergeObjects,
  [MAINTAINERUSER.POST_ADD_USER_SUCCESS]: mergeObjects,
  [MAINTAINERUSER.POST_UPDATE_USER_SUCCESS]: mergeObjects,
  [MAINTAINERUSER.DELETE_USER_SUCCESS]: deleteObject,
  [MAINTAINERUSER.PATCH_ENABLE_MAILER_SUCCESS]: mergeObjects,

  [MAINTAINERWORKSHOP.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [MAINTAINERWORKSHOP.POST_ADD_WORKSHOP_SUCCESS]: mergeObjects,
  [MAINTAINERWORKSHOP.POST_UPDATE_WORKSHOP_SUCCESS]: mergeObjects,
  [MAINTAINERWORKSHOP.DELETE_WORKSHOP_SUCCESS]: deleteObject,

  [MAINTAINERVEHICLE.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [MAINTAINERVEHICLE.POST_ADD_VEHICLE_SUCCESS]: mergeObjects,
  [MAINTAINERVEHICLE.POST_UPDATE_VEHICLE_SUCCESS]: mergeObjects,
  [MAINTAINERVEHICLE.DELETE_VEHICLE_SUCCESS]: deleteObject,
  [MAINTAINERVEHICLE.PATCH_ENABLE_VEHICLE_SUCCESS]: mergeObjects,

  [MAINTAINEREVENTTYPE.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [MAINTAINEREVENTTYPE.POST_ADD_EVENTTYPE_SUCCESS]: mergeObjects,
  [MAINTAINEREVENTTYPE.POST_UPDATE_EVENTTYPE_SUCCESS]: mergeObjects,
  [MAINTAINEREVENTTYPE.DELETE_EVENTTYPE_SUCCESS]: deleteObject,

  [MAINTAINERPREVENTIVEMAINTENANCE.GET_STATE_FROM_API_SUCCESS]: mergeObjects,
  [MAINTAINERPREVENTIVEMAINTENANCE.POST_ADD_PREVENTIVEMAINTENANCE_SUCCESS]: mergeObjects,
  [MAINTAINERPREVENTIVEMAINTENANCE.POST_UPDATE_PREVENTIVEMAINTENANCE_SUCCESS]:
    mergeObjectWithoutManyRelations,
  [MAINTAINERPREVENTIVEMAINTENANCE.DELETE_PREVENTIVEMAINTENANCE_SUCCESS]: deleteObject,

  [LOGIN.LOGIN_USER_SUCCESS](state, action) {
    state.user = action.payload
    state.loadingUser = false
  },
  [APP.GET_USER_SUCCESS](state, action) {
    state.user = action.payload
    state.loadingUser = false
  },
  [APP.GET_USER](state, action) {
    state.loadingUser = true
  },
  [APP.GET_USER_FAIL](state, action) {
    state.user = {}
    state.loadingUser = false
  },
  [APP.LOG_OUT](state, action) {
    state.user = {}
  },
  [APP.TOGGLE_DRAWER](state, action) {
    state.drawerIsOpen = !state.drawerIsOpen
  },
  [APP.TOGGLE_ERROR](state, action) {
    state.error = false
    state.errorMsg = !state.errorMsg
  },
  [APP.SET_ERROR](state, action) {
    state.error = true
    state.errorMsg = action.payload.msg
  },
  [APP.TOGGLE_SUCCESS](state, action) {
    state.success = false
    state.successMsg = !state.successMsg
  },
  [APP.TOGGLE_MODAL_DOWNLOAD](state, action) {
    state.url_file = action?.payload?.url ?? ""
    state.modalDownload = !state.modalDownload
  },
  [APP.SET_SUCCESS](state, action) {
    state.success = true
    state.successMsg = action.payload.msg
  },
  [APP.SET_LOADER](state, action) {
    state.loaders[action.id] = action.state
  },
})

export default app
