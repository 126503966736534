import React, { useState, useEffect } from "react"
import { CardContent, withStyles, Card, Grid, Typography } from "@material-ui/core"

import saga from "./saga"
import { maintainerEventTypeActions } from "./reducer"
import { composedComponent, falsy } from "utils/functions"
import { appActions } from "commons/reducer"
import styles from "./styles"

import TableEventType from "./components/TableEventType"
import DeleteModal from "commons/components/DeleteModal"
import EventTypeForm from "./components/EventTypeForm"
import MenuButton from "commons/components/MenuButton"

const MaintainerEventType = (props) => {
  const { classes, actions, controls } = props
  useEffect(() => {
    actions.getStateFromApi()
  }, [])

  const [eventTypeList, setEventTypeList] = useState([])
  useEffect(() => {
    const filteredEventTypes = Object.values(props.objects.eventTypes)
    setEventTypeList(filteredEventTypes)
  }, [props.objects.eventTypes])

  function onChangeControls(event) {
    const property = !!event.target.name ? event.target.name : event.currentTarget.id
    actions.changeControls({ [property]: event.target.value }, { [property]: false })
  }

  const onToggleModelDetail = () => {}

  const onToggleModel = () => {
    actions.toggleCreateModal()
  }

  const onToggleUpdatedModal = (id = "") => {
    if (typeof id === "number" && !controls.toEditId.localeCompare("")) {
      const item = Object.values(props.objects.eventTypes).find((item) => item.id === Number(id))
      actions.toggleUpdatedModal(item)
    } else {
      actions.toggleUpdatedModal()
    }
  }

  const handleOpenDeletedModal = (id = "") => {
    actions.toggleDeletedModal(id)
  }

  const submit = () => {
    if (falsy(props.formControls.name)) {
      props.actions.setInvalidControls({
        name: falsy(props.formControls.name),
      })
    } else {
      if (props.edit) {
        actions.updateEventType(controls.toEditId, props.formControls)
      } else {
        actions.addEventType(props.formControls)
      }
    }
  }

  const submitDelete = () => {
    actions.deleteEventType(controls.toDeleteId)
  }

  const normalizeToSelect = (data) => {
    data = Object.values(data)
    const selectData = data.map((model) => ({ value: model.id, label: model.label }))
    return selectData
  }

  return (
    <>
      <Grid container justify="center" alignItems="center" style={{ margin: 0, height: "100vh" }}>
        <Grid item md={12} lg={12} xs={12}>
          <Card>
            <CardContent>
              <Typography className={classes.title} variant="h3" component="h3">
                Tipo de eventos
              </Typography>
              <TableEventType
                className={{ icon: classes.icon, p: classes.p }}
                data={eventTypeList}
                onModal={onToggleModelDetail}
                handleOpenUpdatedModal={onToggleUpdatedModal}
                handleOpenDeletedModal={handleOpenDeletedModal}
              />
            </CardContent>
          </Card>
        </Grid>
        <DeleteModal
          toggleForm={handleOpenDeletedModal}
          isOpen={props.modal.deleted.status}
          title="Eliminar tipo de evento"
          description="¿Desea eliminar este tipo de evento?"
          labelAction="eliminar"
          submit={submitDelete}
        />

        <EventTypeForm
          className={classes}
          isOpen={props.modal.form.status}
          edit={props.edit}
          controls={props.formControls}
          errors={props.invalidControls}
          helperFilters={{ roles: normalizeToSelect(props.objects.roles) }}
          changeControls={onChangeControls}
          submit={submit}
          toggleForm={props.edit ? onToggleUpdatedModal : onToggleModel}
        />
      </Grid>

      <MenuButton className={classes.fab} handleToggle={onToggleModel} />
    </>
  )
}

export default composedComponent(MaintainerEventType, saga, {
  saga: "sagaMaintainerEventType",
  middlewares: [withStyles(styles)],
  states: [
    "maintainerEventType.formControls",
    "maintainerEventType.invalidControls",
    "maintainerEventType.controls",
    "maintainerEventType.modal",
    "maintainerEventType.edit",
    "app.objects",
  ],
  actions: [maintainerEventTypeActions, appActions],
})
