const styles = (theme) => ({
  field: {
    width: "100%",
  },
  buttonsContainer: {
    marginTop: "10px",
    marginBottom: "10px",
  },
})

export default styles
