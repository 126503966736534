import React, { useState, useEffect } from "react"
import { CardContent, withStyles, Card, Grid, Typography } from "@material-ui/core"

import saga from "./saga"
import { maintainerVehicleActions } from "./reducer"
import { composedComponent, falsy } from "utils/functions"
import { appActions } from "commons/reducer"
import styles from "./styles"

import TableVehicle from "./components/TableVehicle"
import Modal from "commons/components/DeleteModal"
import VehicleForm from "./components/VehicleForm"
import MenuButton from "commons/components/MenuButton"

const MaintainerVehicle = (props) => {
  const { classes, actions, controls } = props
  useEffect(() => {
    actions.getStateFromApi();
  }, [])

  const [vehicleList, setVehicleList] = useState([])
  useEffect(() => {
    const filteredVehicles = Object.values(props.objects.vehicles)
    setVehicleList(filteredVehicles)
  }, [props.objects.vehicles])

  function onChangeControls(event) {
    const property = !!event.target.name ? event.target.name : event.currentTarget.id
    actions.changeControls({ [property]: event.target.value }, { [property]: false })
  }

  const onToggleModelDetail = () => {}

  const onToggleModel = () => {
    actions.getVehicleTypes();
    actions.toggleCreateModal()
  }

  const onToggleUpdatedModal = (id = "") => {
    actions.getVehicleTypes();

    if (typeof id === "number" && !controls.toEditId.localeCompare("")) {
      const item = Object.values(props.objects.vehicles).find((item) => item.id === Number(id))
      actions.toggleUpdatedModal(item)
    } else {
      actions.toggleUpdatedModal()
    }
  }

  const handleOpenDeletedModal = (id = "") => {
    actions.toggleDeletedModal(id)
  }

  const handleOpenEnableModal = (id = "", enable = true) => {
    let info = {
      title: "Desactivar vehículo",
      description: "¿Desea desactivar este vehículo?",
      labelAction: "Desactivar",
      colorActionBtn: "secondary",
      enable: enable,
    }
    if (!enable) {
      info = {
        title: "Activar vehículo",
        description: "¿Desea activar este vehículo?",
        labelAction: "Activar",
        colorActionBtn: "primary",
        enable: enable,
      }
    }
    actions.toggleEnableModal(id, info)
  }

  const submit = () => {
    if (
      props.formControls.vehicleTypeId === null ||
      falsy(props.formControls.plateNumber) ||
      falsy(props.formControls.model) ||
      falsy(props.formControls.vin)
    ) {
      props.actions.setInvalidControls({
        vehicleTypeId: props.formControls.vehicleTypeId === null,
        plateNumber: falsy(props.formControls.plateNumber),
        model: falsy(props.formControls.model),
        vin: falsy(props.formControls.vin),
      })
    } else {
      if (props.edit) {
        actions.updateVehicle(controls.toEditId, props.formControls)
      } else {
        actions.addVehicle(props.formControls)
      }
    }
  }

  const submitEnable = () => {
    actions.enableVehicle(controls.toEnableId, props.modal.enable.info)
  }

  const submitDelete = () => {
    actions.deleteVehicle(controls.toDeleteId)
  }

  const normalizeToSelect = (data) => {
    data = Object.values(data)
    const selectData = data.map((model) => ({ value: model.id, label: model.label }))
    return selectData
  }

  return (
    <>
      <Grid container justify="center" alignItems="center" style={{ margin: 0, height: "100vh" }}>
        <Grid item md={12} lg={12} xs={12}>
          <Card>
            <CardContent>
              <Typography className={classes.title} variant="h3" component="h3">
                Vehículos
              </Typography>
              <TableVehicle
                className={{ icon: classes.icon, p: classes.p }}
                data={vehicleList}
                onModal={onToggleModelDetail}
                handleOpenUpdatedModal={onToggleUpdatedModal}
                handleOpenDeletedModal={handleOpenDeletedModal}
                handleOpenEnableModal={handleOpenEnableModal}
              />
            </CardContent>
          </Card>
        </Grid>

        <Modal
          toggleForm={handleOpenDeletedModal}
          isOpen={props.modal.deleted.status}
          title="Eliminar vehículo"
          description="¿Desea eliminar este vehículo?"
          labelAction="eliminar"
          submit={submitDelete}
        />

        <Modal
          toggleForm={handleOpenEnableModal}
          isOpen={props.modal.enable.status}
          title={props.modal.enable.info.title}
          description={props.modal.enable.info.description}
          labelAction={props.modal.enable.info.labelAction}
          colorActionBtn={props.modal.enable.info.colorActionBtn}
          submit={submitEnable}
        />

        {props.modal.form.status &&
          <VehicleForm
            className={classes}
            isOpen={props.modal.form.status}
            edit={props.edit}
            controls={props.formControls}
            vehicleTypes={props.objects.vehicleTypes}
            errors={props.invalidControls}
            helperFilters={{ roles: normalizeToSelect(props.objects.roles) }}
            changeControls={onChangeControls}
            submit={submit}
            toggleForm={props.edit ? onToggleUpdatedModal : onToggleModel}
            closeModal={actions.closeVehicleModal}
          />
        }
      </Grid>

      <MenuButton className={classes.fab} handleToggle={onToggleModel} />
    </>
  )
}

export default composedComponent(MaintainerVehicle, saga, {
  saga: "sagaMaintainerVehicle",
  middlewares: [withStyles(styles)],
  states: [
    "maintainerVehicle.formControls",
    "maintainerVehicle.invalidControls",
    "maintainerVehicle.controls",
    "maintainerVehicle.modal",
    "maintainerVehicle.edit",
    "maintainerVehicle.vehicleTypes",
    "app.objects",
  ],
  actions: [maintainerVehicleActions, appActions],
})
