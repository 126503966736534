// @ts-nocheck
import React, { useState } from "react"
import {
  Drawer,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  CardMedia,
} from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"
import styles from "./styles"
import Paths from "utils/paths"

import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import WorkIcon from "@material-ui/icons/Work"
import ReceiptIcon from "@material-ui/icons/Receipt"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"

import AuthUserContext from "commons/context/user"
// import LOGO from "utils/images/logo-salfa-h-color300.png"
import LOGO from "utils/images/logo-carabinero-h-blanco300.png"

const useStyles = makeStyles(styles)

const SideBar = (props) => {
  const [open, setOpen] = useState(props.isOpen)
  const classes = useStyles()
  const authUser = React.useContext(AuthUserContext)

  const handleClick = () => {
    setOpen(!open)
  }

  const views = [
    {
      label: "Servicios",
      module: Paths.HOME,
      icon: <ReceiptIcon />,
    },
    {
      label: "Cotizaciones",
      module: Paths.BUDGETS,
      icon: <MonetizationOnIcon />,
    },
  ]

  const maintainers = [
    { label: "Usuarios", module: Paths.USERS_MANTAINER },
    { label: "Talleres", module: Paths.WORKSHOPS_MANTAINER },
    { label: "Vehículos", module: Paths.VEHICLES_MANTAINER },
    { label: "Tipo de eventos", module: Paths.EVENTTYPES_MANTAINER },
    { label: "Mantenciones preventivas", module: Paths.PREVENTIVEMAINTENANCES_MANTAINER },
  ]

  const renderMaintainers = () => {
    if (authUser?.rol?.name.includes("Administrator"))
      return (
        <>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <WorkIcon />
            </ListItemIcon>
            <ListItemText primary="Mantenedores" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {maintainers.map((maintainer) => (
                <ListItem
                  onClick={props.handleItemClick(maintainer.module)}
                  button
                  className={classes.nested}
                  key={`sub-menu-${maintainer.label}`}
                >
                  <ListItemText primary={maintainer.label} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </>
      )
  }

  return (
    <Drawer
      classes={{ root: classes.root, paperAnchorLeft: classes.paperAnchorLeft }}
      open={props.isOpen}
      onClose={props.toggleDrawer}
    >
      <Typography
        variant="h6"
        className={classes.titles}
        onClick={props.handleItemClick(Paths.HOME)}
      >
        <CardMedia className={classes.icon} image={LOGO} title="Salfa" />
      </Typography>
      <List>
        {views.map((view, index) => (
          <ListItem onClick={props.handleItemClick(view.module)} button key={`menu-${view.label}`}>
            <ListItemIcon>{view.icon}</ListItemIcon>
            <ListItemText primary={view.label} />
          </ListItem>
        ))}
        {renderMaintainers()}
      </List>
    </Drawer>
  )
}

export default SideBar
