const styles = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    marginBottom: "20px",
    marginTop: "20px",
    "& .MuiTypography-h4": {
      marginBottom: "20px",
    },
  },
  card: {
    minHeight: "350px !important",
  },
  fixedBtnAdd: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(10),
  },
  p: {
    display: "block",
    textTransform: "lowercase",
    "&::first-letter": {
      textTransform: "uppercase",
    },
  },
  pEllipsis: {
    display: "block",
    textTransform: "lowercase",
    textOverflow: "ellipsis",
    maxHeight: "150px",
    maxWidth: "300px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "&::first-letter": {
      textTransform: "uppercase",
    },
  },
  field: {
    paddingBottom: "10px",
  },
})

export default styles
