import { truthty, getItemInStorage } from "utils/functions"

// @ts-nocheck
export const apiSuccess = (entity, payload) => ({
  type: entity,
  payload,
})

export default function* apiRequest(url, options, useDefaultHeader = true) {
  const session = getItemInStorage("user")
  const authorizationHeader = {
    Authorization: `Bearer ${session && session.token}`,
  }
  const defaultHeaders = { "Content-Type": "application/json" }

  let content = options ?? {}
  if (useDefaultHeader) {
    content.headers = { ...content.headers, ...defaultHeaders }
  }
  content.headers = { ...content.headers, ...authorizationHeader }

  const res = yield fetch(`${window.location.origin}/${url}`, content)
    .then((response) => {
      if (response.ok) {
        return response.json().then((res) => ({ msg: res?.message, ...res }))
      } else if (response.status === 401) {
        localStorage.clear()
        // window.location.replace(window.location.origin);
      } else {
        return response.json().then((error) => {
          return {
            error: true,
            msg: error?.message,
            errorMsg: error?.message,
          }
        })
      }
    })
    .catch((response) => {
      console.error(response)
    })
  return res
}

export function objectToQueryString(obj) {
  return Object.keys(obj)
    .filter((k) => truthty(obj[k]))
    .map((k) => {
      if (truthty(obj[k])) {
        return Array.isArray(obj[k])
          ? obj[k]
              .map((o) => `${encodeURIComponent(k)}[]=${encodeURIComponent(o)}`)
              .join("&")
          : `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`
      } else {
        return ""
      }
    })
    .join("&")
}

export function* post(path, body) {
  const options = {
    method: "POST",
    body: JSON.stringify(body),
  }

  return yield apiRequest(path, options)
}

export function* postForm(path, body, useDefaultHeader = true) {
  const session = getItemInStorage("user")
  const options = {
    method: "POST",
    body,
    headers: {
      Authorization: `Bearer ${session && session.token}`,
      // "Content-Type": `multipart/form-data; boundary=<calculated when request is sent>`,
    },
  }

  return yield apiRequest(path, options, useDefaultHeader)
}

export function* update(path, body, useDefaultHeader = true) {
  const session = getItemInStorage("user")
  const options = {
    method: "PATCH",
    body,
    headers: {
      Authorization: `Bearer ${session && session.token}`,
      // "Content-Type": `multipart/form-data; boundary=<calculated when request is sent>`,
    },
  }

  return yield apiRequest(path, options, useDefaultHeader)
}

export function* destroy(path, query) {
  const options = {
    method: "delete",
  }
  return yield apiRequest(`${path}${query ? `?${query}` : ""}`, options)
}

export function* get(path, query) {
  const options = {
    method: "GET",
  }
  return yield apiRequest(`${path}${query ? `?${query}` : ""}`, options)
}

export function* downloadFileBlob(path, query) {
  const options = {
    method: "GET",
  }

  const session = getItemInStorage("user")
  const authorizationHeader = {
    Authorization: `Bearer ${session && session.token}`,
  }
  let content = options ?? {}
  content.headers = { ...content.headers, ...authorizationHeader }

  return yield fetch(path, content)
    .then((response) => {
      if (response.ok) {
        return response.blob()
      } else if (response.status === 401) {
        localStorage.clear()
      } else {
        return {
          error: true,
          msg: "Error interno en el servidor (503)",
          errorMsg: "Error interno en el servidor (503)",
        }
      }
    })
    .then((data) => {
      const previewUrl = window.URL.createObjectURL(data)
      const link = document.createElement("a")
      link.style.display = "none"
      link.href = previewUrl
      link.download = `services${Date.now()}.xlsx`
      link.click()
      link.remove()
      setTimeout(function () {
        URL.revokeObjectURL(previewUrl)
      }, 100)
      return { msg: "Exportación exitosa!" }
    })
    .catch((error) => ({
      error: true,
      msg: error?.message,
      errorMsg: error?.message,
    }))
}
// https://stackoverflow.com/questions/3749231/download-file-using-javascript-jquery
// https://blog.logrocket.com/programmatic-file-downloads-in-the-browser-9a5186298d5c/
