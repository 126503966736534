import { Button, Card, CardContent, Grid, Link, Typography, withStyles } from "@material-ui/core"
import React, { useEffect } from "react"
import { composedComponent } from "utils/functions"
import styles from "./styles"
import moment from "moment"
import "./styles.scss"
import saga from "./saga"
import BudgetsTable from "./components/BudgetsTable"
import { appActions } from "commons/reducer"
import { budgetsMaintainerActions } from "./reducer"

const BudgetMaintainer = (props) => {
  const { classes, actions } = props

  useEffect(() => {
    actions.getBudgets()
  }, [])

  const changeFilter = (object) => {
    if (object.name) {
      actions.changeFilters(object)
    } else {
      actions.changeFilters({ name: object.target.name, value: object.target.value })
    }
  }

  const clearFilters = () => {
    actions.clearFilters()
  }

  const filterBudgets = (event, filters) => {
    event.preventDefault()
    let test = { ...filters }
    if (test) {
      if (test.dateService === null) {
        test.dateService = ""
      } else {
        test.dateService = moment(filters.dateService).format("YYYY-MM-DD")
      }
      if (test.addedDate === null) {
        test.addedDate = ""
      } else {
        test.addedDate = moment(filters.addedDate).format("YYYY-MM-DD")
      }
    }
    actions.getBudgets(test)
  }

  const test = props.controls.budgets ? props.controls.budgets : [{}]
  return (
    <Card style={{ marginTop: "30px" }}>
      <CardContent>
        <div className={classes.divContainer}>
          <Typography className={classes.title} variant="h3" component="h3">
            Cotizaciones
          </Typography>
        </div>
        <BudgetsTable
          className={{
            icon: classes.icon,
            p: classes.p,
            boxLineaCard: classes.boxLineaCard,
            containerFilter: classes.containerFilter,
            leyendIconContainer: classes.leyendIconContainer,
            leyendIcon: classes.leyendIcon,
            leyendIconSpan: classes.leyendIconSpan,
            button: classes.button,
            field: classes.field,
            clearFiltersButton: classes.clearFiltersButton,
            formControl: classes.formControl,
          }}
          loading={props.controls.loading}
          data={test}
          onFilter={filterBudgets}
          changeFilter={changeFilter}
          filters={props.controls.filters}
          clearFilters={clearFilters}
        ></BudgetsTable>
      </CardContent>
    </Card>
  )
}

export default composedComponent(BudgetMaintainer, saga, {
  saga: "budgetMaintainerSaga",
  states: ["maintainerBudget.controls"],
  middlewares: [withStyles(styles)],
  actions: [budgetsMaintainerActions, appActions],
})
