import React, { useState, useEffect, useCallback } from "react"
import { Link } from "react-router-dom"
// Project
import produce from "immer"
import saga from "./saga"
import { loginActions } from "./reducer"
import { truthty, getParameterByName, composedComponent } from "utils/functions"
import { appActions } from "commons/reducer"
import { useForm } from "utils/hooks/useForm"
// import LOGO from "utils/images/logo-salfa-h-color300.png"
import LOGO from "utils/images/logo-carabinero-h-color300.png"
import Paths from "utils/paths"

// Material
import {
  CardContent,
  CircularProgress,
  Typography,
  withStyles,
  CardMedia,
  Button,
  Card,
  Grid,
} from "@material-ui/core"
import AlertSnackbar from "commons/components/AlertSnackbar"
import CustomField from "commons/components/CustomField"

import styles from "./styles"
import { VALIDATIONS } from "utils/validations"

const Login = (props) => {
  const { classes, actions, controls } = props
  const [state, setState] = useState({
    email: "",
    password: "",
  })

  const onChangeText = useCallback((id, value) => {
    setState(
      produce((draft) => {
        draft[id] = value?.trim()
      })
    )
  }, [])

  const { onChange, onSubmit, errors } = useForm(state, null, {
    onChange: onChangeText,
    validations: {
      password: [{ ...VALIDATIONS.REQUIRED, message: "Contraseña obligatoria" }],
      email: [{ ...VALIDATIONS.REQUIRED, message: "Email obligatorio" }, VALIDATIONS.IS_EMAIL],
    },
  })

  function onClickLogin(event) {
    event.preventDefault()
    onSubmit(() => props.actions.login(state, controls.redirectUrl))
  }

  useEffect(() => {
    if (truthty(props.user)) {
      return props.history.push(Paths.HOME)
    }
  }, [props.user, props.history])

  useEffect(() => {
    const token = getParameterByName("token", props.location.search)
    if (token) {
      props.actions.verify(token)
    }
  }, [props.actions, props.location.seaprops, props.location.search])

  return (
    <div style={styles.container}>
      <div style={styles.containerContent}>
        <Grid container justify="center" alignItems="center" style={{ margin: 0, height: "100vh" }}>
          <Grid item md={4} lg={4} xs={12}>
            <Card>
              <CardContent style={styles.cardContent}>
                <CardMedia className={classes.image} image={LOGO} title="Salfa" />
                <form onSubmit={onClickLogin}>
                  <Grid container spacing={3} justify="center">
                    <Grid item xs={10}>
                      <CustomField
                        className={classes.field}
                        label="Correo electrónico"
                        id="email"
                        value={state.email}
                        onChange={onChange}
                        style={{ paddingBottom: "8px" }}
                        fullWidth
                        error={!!errors.email}
                        helperText={errors.email}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <CustomField
                        className={classes.field}
                        label="Contraseña"
                        onChange={onChange}
                        value={state.password}
                        id="password"
                        fullWidth
                        type="password"
                        error={!!errors.password}
                        helperText={errors.password}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      container
                      justify="center"
                      alignItems="center"
                      className={classes.textAlign}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        // disabled={props.controls.loading || errors.email || errors.password}
                      >
                        {props.controls.loading ? (
                          <CircularProgress className={classes.progress} />
                        ) : (
                          "Ingresar"
                        )}
                      </Button>
                    </Grid>

                    <Grid item xs={10}>
                      <Typography variant="caption" color="primary">
                        <Link
                          to={Paths.RECOVERY_PASSWORD}
                          style={{
                            textDecoration: "none",
                            color: "#0d47a1",
                            fontSize: "1rem",
                          }}
                          variant="inherit"
                          color="primary"
                        >
                          ¿Olvidaste tu contraseña?
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
          <AlertSnackbar
            pop={controls.error}
            severity="error"
            message={controls.message}
            onClose={actions.disableErrors}
          />
        </Grid>
      </div>
    </div>
  )
}

export default composedComponent(Login, saga, {
  saga: "sagaLogin",
  middlewares: [withStyles(styles)],
  states: [
    "login.controls",
    "app.user",
    "app.errorMsg",
    "app.error",
    "app.successMsg",
    "app.success",
  ],
  actions: [loginActions, appActions],
})
