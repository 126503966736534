import React, { useEffect, useState } from "react"
import { withRouter, Route } from "react-router-dom"
import { connect } from "react-redux"
import { compose } from "redux"

import socketIOClient from "socket.io-client"
import { appActions } from "commons/reducer"
import { actionProps, selectState } from "utils/reduxActions"
import { ReactReduxContext } from "config/configureStore"
import connectSaga from "utils/saga"
import Paths from "utils/paths"
import PrivateRoute from "utils/hooks/PrivateRoute"
// import PublicRoute from "utils/hooks/PublicRoute"
import saga from "../commons/saga"

import Login from "screens/Login"
import RecoveryPassword from "screens/RecoveryPassword"
import Home from "screens/Home"
import Service from "screens/Service"
import User from "screens/Maintainers/User"
import WorkShop from "screens/Maintainers/WorkShop"
import Vehicle from "screens/Maintainers/Vehicle"
import EventType from "screens/Maintainers/EventType"
import PreventiveMaintenance from "screens/Maintainers/PreventiveMaintenance"
import ContractDocuments from "screens/ContractDocuments"

// styles
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import { esES } from "utils/Translate/esEs"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import esLocale from "date-fns/locale/es"
import AuthUserContext from "commons/context/user"
import SocketClientContext from "commons/context/socket"
import Budget from "screens/Budget"
import BudgetMaintainer from "screens/BudgetMaintainer"
import BudgetDetails from "screens/BudgetDetails"
// import Page404 from "commons/components/Page404"

const theme = createTheme(
  {
    palette: {
      primary: {
        // main: "#072140",
        main: "rgb(24 85 23)",
        contrastText: "white",
      },
      secondary: {
        main: "#ff1744",
      },
    },
    typography: {
      fontFamily: {
        useNextVariants: true,
        fontFamily: ["Roboto"],
      },
      body1: {
        fontFamily: ["Roboto"],
        color: "#545d65",
      },
      body2: {
        fontFamily: ["Roboto"],
        color: "#545d65",
      },
      h3: {
        fontFamily: ["Roboto"],
        color: "#545d65",
        fontSize: "40px",
      },
      h5: {
        fontFamily: ["Roboto"],
        color: "#545d65",
        fontSize: "20px",
      },
    },
    overrides: {
      MuiButton: {
        contained: {
          "&:hover": {
            backgroundColor: "#185517d9",
            color: "white",
          },
        },
      },
    },
  },
  esES
)

const App = (props) => {
  useEffect(() => {
    props.actions.getUser()
  }, [props.actions])

  const [socketId, setSocketId] = useState(null)
  useEffect(() => {
    const io = socketIOClient.connect()

    io.on("ExportExcel", (socketId, data) => {
      props.actions.toggleModalDownload(data)
    })

    io.on("connect", () => {
      setSocketId(io?.id)
    })

    return () => {
      return io.disconnect()
    }
  }, [])

  // return props.loadingUser ? (
  //   <>
  //     <Skeleton variant="rect" width={220} height={118} />
  //     <Skeleton variant="rect" width={220} height={118} />
  //   </>
  // ) :
  return (
    <SocketClientContext.Provider value={socketId}>
      <AuthUserContext.Provider value={props?.user?.user}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
            <PrivateRoute path={Paths.HOME} component={Home} />
            <PrivateRoute exact path={Paths.CREATE_BUDGET} component={Budget} />
            <PrivateRoute exact path={Paths.EDIT_BUDGET} component={Budget} />
            <PrivateRoute exact path={Paths.SERVICE_DETAILS} component={Service} />
            <PrivateRoute exact path={Paths.BUDGET_DETAILS} component={BudgetDetails} />
            <PrivateRoute path={Paths.BUDGETS} component={BudgetMaintainer} />
            <PrivateRoute path={Paths.USERS_MANTAINER} component={User} />
            <PrivateRoute path={Paths.WORKSHOPS_MANTAINER} component={WorkShop} />
            <PrivateRoute path={Paths.VEHICLES_MANTAINER} component={Vehicle} />
            <PrivateRoute path={Paths.EVENTTYPES_MANTAINER} component={EventType} />
            <PrivateRoute
              path={Paths.PREVENTIVEMAINTENANCES_MANTAINER}
              component={PreventiveMaintenance}
            />
            <PrivateRoute path={Paths.CONTRACT_DOCUMENTS} component={ContractDocuments} />

            <Route exact path={Paths.LOGIN} component={Login} />
            <Route exact path={Paths.RECOVERY_PASSWORD} component={RecoveryPassword} />
            <Route exact path={Paths.UPDATE_PASSWORD} component={RecoveryPassword} />
            {/* <Route exact path="/*" component={Page404} /> */}
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </AuthUserContext.Provider>
    </SocketClientContext.Provider>
  )
}

const withSaga = connectSaga({ key: "sagaApp", saga })

const withConnect = connect(
  selectState("app.user", "app.loadingUser"),
  actionProps(appActions),
  null,
  { context: ReactReduxContext }
)

export default compose(withRouter, withConnect)(withSaga(App))
